import(/* webpackMode: "eager" */ "/app/apps/ptw/src/style/index.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lng]/[tenant]/[...slugs]/_fonts.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--f-sans\",\"fallback\":[\"sans-serif\"],\"src\":[{\"path\":\"./fonts/active_grotesk_300.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_400.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_500.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_600.woff2\",\"weight\":\"600\",\"style\":\"normal\"}]}],\"variableName\":\"activeGrotesk\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lng]/[tenant]/[...slugs]/_fonts.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--f-serif\",\"fallback\":[\"sans-serif\"],\"src\":[{\"path\":\"./fonts/active_grotesk_300.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_400.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_500.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_600.woff2\",\"weight\":\"600\",\"style\":\"normal\"}]}],\"variableName\":\"activeGroteskHeadings\"}");
;
import(/* webpackMode: "eager" */ "/app/packages/ui-web/src/cssVariables/CssVariables.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui-web/src/muiTheme/MuiTheme.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui-web/src/searchHighlighterProvider/SearchHighlighterProvider.tsx");
